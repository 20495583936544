import React from "react"
import styled from "styled-components"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "../Galleries"
import { GoCalendar } from "react-icons/go"


const DateInputSpan = styled.span`
color:#1675e0;
font-size:14px
`

const DateDiv = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;
  border-radius:6px;
  border-color: rgb(222, 222, 222);

`

const SubHeaderPane = styled.div`
	display: flex;
  flex-direction: column;
	width: 100%;
	height:80px;

	/* Medium devices (tablets, 768px and up) */
  @media (min-width: 1400px) { 
		flex-direction: row;
	}
`

const BoxSide = styled.div`
  display: flex;
  flex-basis: 30%;
  `
const BoxCenter = styled.div`
  display: flex;
  flex-basis: 40%;
  align-items:center;
  justify-content:center;
  `


const FilterOptions = styled.div`
	display: flex;
	flex: 1;
  flex-direction: column;

	.📦w_240px {
		max-width: 195px;
	}	

	input {
		max-width: 195px;
		height: 40px;
		border: 1px solid #DEDEDE;
		padding: 11px 1rem;
		border-radius: 4px;
		box-shadow: none;
		font-weight: normal;
		font-size: 16px;
		margin: 5px 0;
		color: #362165;

		div {
			max-width: 195px !important;
		}
	}
	
	input + button {
		height: 40px;
		margin: 5px 0;
		margin-left: -32px;
	}


	/* align-self: flex-start; */
	margin-left: -15px;

	p {
		min-width: 110px;
		display: inline-block;
	}

  @media (min-width: 1200px) { 
		flex-direction: row;
		justify-content: flex-end;
		input {
			width: 240px !important;
		}

		p {
			min-width: auto;
			display: inline-block;
		}
	}
`
const TabTitle = styled.h2`
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 25px;
color: ${props => props.selected ? "#F06878" : "#362165"};
margin-top:50% !important;
cursor:pointer;
`
const TabWrapper = styled(Col)`
border-bottom:${props => props.selected ? "5px solid #F06878" : "5px solid #fff"};
height:100%
`

const GallerySubHeader = ({ selectedDate, datePickerSettings, selectedFilter, sortPhotos, doFilter, filteredPhotos }) => {

  return (
    <SubHeaderPane>
      <FilterOptions>
        <BoxSide/>
        <BoxCenter marginLeft={15}>
          <Row style={{ height: "100%", alignItems: "center" }}>

            <TabWrapper selected={selectedFilter === "Date"} onClick={() => sortPhotos("Date", filteredPhotos)}>
              <TabTitle selected={selectedFilter === "Date"}>
                Newest
              </TabTitle>
            </TabWrapper>
            <TabWrapper selected={selectedFilter === "Popular"} onClick={() => sortPhotos("Popular", filteredPhotos)}>
              <TabTitle selected={selectedFilter === "Popular"}>
                Popular
              </TabTitle>
            </TabWrapper>
            <Col>
              {/*<DateRangePicker format={"ddd,MMM DD,YYYY"}*/}
              {/*                 placement="bottom"*/}
              {/*                 preventOverflow*/}
              {/*                 ranges={ranges}*/}
              {/*                 value={selectedDate}*/}
              {/*                 onChange={value => {*/}
              {/*                   doFilter(null, value)*/}
              {/*                 }}*/}
              {/*/>*/}

              <DateRangePicker initialSettings={datePickerSettings} onApply={(event, picker) => {
                doFilter(null, [picker.startDate.toDate(), picker.endDate.toDate()])
              }}>
                <DateDiv>
                  <DateInputSpan>{moment(selectedDate[0]).format("ddd,MMM DD,YYYY")}~{moment(selectedDate[1]).format("ddd,MMM DD,YYYY")}</DateInputSpan>
                  &nbsp;
                  <GoCalendar style={{color:'grey'}}  />
                </DateDiv>
              </DateRangePicker>
            </Col>
          </Row>
        </BoxCenter>
        <BoxSide/>
      </FilterOptions>
    </SubHeaderPane>
  )
}


export default GallerySubHeader
