import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import dateFormat from 'dateformat'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 1rem 0 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);


  img {
    /* border-radius: 60px; */
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;
`

const PicDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: red;
  background: rgba(54, 33, 101, 0.6);
  border-radius: 20px;
  width: 86px;
  height: 26px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
`

const GalleryPhoto = ({photo, onClick}) => {    
	return (
		<div className="col-lg-3 col-md-6 col-sm-12 col-12">
			<Wrapper onClick={onClick}>
				<img src={photo.photoURL} height={313} alt=""/>
				<Footer>
					<PicDate>{dateFormat(photo.createDate.toDate(), 'mm.dd.yyyy')}</PicDate>
				</Footer>
			</Wrapper>
		</div>
	)
}

GalleryPhoto.propTypes = {
	photo: PropTypes.any,
	onClick: PropTypes.any
}


export default GalleryPhoto
