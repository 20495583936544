import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Pane, Combobox } from "evergreen-ui"
import { Container } from "./primitives/Container"
import GalleryPhoto from "./primitives/GalleryPhoto"
import ProfileHeader from "./primitives/ProfileHeader"
import Footer from "./primitives/Footer"
import ViewPhotoDialog from "./primitives/ViewPhotoDialog"
import { withFirebase } from "./Firebase"
import moment from "moment"
import InfiniteScroll from "react-infinite-scroll-component"
import GalleryHeader from "./primitives/GalleryHeader"
import GallerySubHeader from "./primitives/GallerySubHeader"
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { GoCalendar } from "react-icons/go"
const avatar = require("../assets/images/avatar.gif")
const ico_photos = require("../assets/images/svgs/ico_photos.svg")


const DateInputSpan = styled.span`
color:#1675e0;
font-size:14px
`

const DateDiv = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 5px 10px;
  width: 100%;
  border-radius:6px;
  border-color: rgb(222, 222, 222);

`

const datePickerSettings = {
  startDate: moment().startOf("month").toDate(),
  endDate: moment().endOf("day").toDate(),
  locale: {
    format: "ddd,MMM DD,YYYY",
  },
  ranges: {
    "Last 7 Days": [
      moment().subtract(6, "days").startOf("day").toDate(),
      moment().endOf("day").toDate(),
    ],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("day").toDate(),
    ],
    "Year to Date": [
      moment().startOf("year").toDate(),
      moment().endOf("day").toDate(),
    ],
    Today: [
      moment().startOf("day").toDate(),
      moment().endOf("day").toDate(),
    ],
    Yesterday: [
      moment().subtract(1, "days").startOf("day").toDate(),
      moment().subtract(1, "days").endOf("day").toDate(),
    ],
  },
}
const FilterTitle = styled.h3`
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 172.96%;
text-align: right;
letter-spacing: 0.2em;
text-transform: uppercase;
color: #362165;
`

const Wrapper = styled.div`
	p {
		margin: 0;
		margin-right: 10px;
	}

	h1,h2,h3,h4,img,hr {
		margin: 0;
	}
	
	${Container} {
	 flex-direction: column;
	}
	/* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
		${Container} {
			flex-direction: row;
		}
	}
`

const SubHeaderPane = styled.div`
	display: flex;
  flex-direction: column;
	width: 100%;

	/* Medium devices (tablets, 768px and up) */
  @media (min-width: 1400px) { 
		flex-direction: row;
	}
`

const FilterOptions = styled.div`
	display: flex;
	flex: 1;
  flex-direction: column;

	.📦w_240px {
		max-width: 195px;
	}	

	input {
		max-width: 195px;
		height: 40px;
		border: 1px solid #DEDEDE;
		padding: 11px 1rem;
		border-radius: 4px;
		box-shadow: none;
		font-weight: normal;
		font-size: 16px;
		margin: 5px 0;
		color: #362165;

		div {
			max-width: 195px !important;
		}
	}
	
	input + button {
		height: 40px;
		margin: 5px 0;
		margin-left: -32px;
	}


	/* align-self: flex-start; */
	margin-left: -15px;

	p {
		min-width: 110px;
		display: inline-block;
	}

  @media (min-width: 1200px) { 
		flex-direction: row;
		justify-content: flex-end;
		input {
			width: 240px !important;
		}

		p {
			min-width: auto;
			display: inline-block;
		}
	}
`


const GallerySection = styled.div`
	width: 100%;
`

const GalleryTitle = styled.h2`
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
color: #362165;
`
const PhotosCount = styled.h2`
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 45px;
line-height: 55px;
color: #362165;
`
const GalleryHeaderTitle = styled.h2`
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 35px;
line-height: 123.3%;
text-align: center;
color: #362165;
`


const NothingToShow = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 300px;
	color: #DEDEDE;
	font-size: 2rem;
	font-weight: bold;
`

const defaultGallery = {
  id: "",
  data: {
    accountID: "",
    galleryID: "",
    hashTag: "",
    name: "All Photos",
    isPrivate: false,
    unlisted: true,
  },
}
const ranges = [
  {
    label: "today",
    value: [
      moment().startOf("day").toDate(),
      moment().endOf("day").toDate(),
    ],
  },
  {
    label: "yesterday",
    value: [
      moment().subtract(1, "days").startOf("day").toDate(),
      moment().subtract(1, "days").endOf("day").toDate(),
    ],
  },
  {
    label: "last7Days",
    value: [
      moment().subtract(6, "days").startOf("day").toDate(),
      moment().endOf("day").toDate(),
    ],
  },
  {
    label: "Month to Date",
    value: [
      moment().startOf("month").toDate(),
      moment().endOf("day").toDate(),
    ],
  },
  {
    label: "Year to Date",
    value: [
      moment().startOf("year").toDate(),
      moment().endOf("day").toDate(),
    ],
  },
]

const Galleries = (props) => {

  const { authUser, firebase, galleryID } = props
  const [accountInfo, setAccountInfo] = useState(null)
  const [selectedDate, setSelectedDate] = useState([datePickerSettings.startDate, datePickerSettings.endDate])
  const [selectedGallery, setSelectedGallery] = useState(galleryID ? null : defaultGallery.data)
  const [galleries, setGalleries] = useState([defaultGallery])
  const [photos, setPhotos] = useState([])
  const [filteredPhotos, setFilteredPhotos] = useState([])
  const [showDialog, setShowDialog] = useState({ show: false, index: null })
  const [pagination, setPagination] = useState({ counter: 0, hasMore: true, photos: [] })
  const [selectedFilter, setSelectedFilter] = useState("Date")
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    if (!galleryID && firebase && authUser) {
      firebase.db.collection("accounts")
        .doc(authUser.uid)
        .get()
        .then(doc => {
          const data = doc.data()
          setAccountInfo(data)
          // Create a reference to collection
          const galleriesRef = firebase.db.collection("galleries")
          // Create a query against the collection.
          const galleriesQuery = galleriesRef.where("accountID", "==", authUser.uid)
          galleriesQuery
            .get()
            .then((querySnapshot) => {
              let galleriesData = []
              querySnapshot.forEach((doc) => {
                galleriesData = galleriesData.concat({
                  id: doc.id,
                  data: doc.data(),
                })
              })
              setGalleries([defaultGallery].concat(galleriesData))
              setLoading(false)
            })
            .catch((error) => {
              console.log("Error getting documents: ", error)
            })
        })
    } else if (galleryID && firebase) {
      firebase.db.collection("galleries")
        .doc(galleryID)
        .get().then(doc => {
          if (doc.exists) {
            const data = doc.data()
            if (data) {
              if (data.isPrivate) {
                navigate("/404")
              } else {
                setSelectedGallery(data)
                setLoading(false)
              }

            }
          } else {
            navigate("/404")
          }
        })
    }
  }, [firebase])

  useEffect(() => {
    if (!loading) {
      if (galleryID && selectedGallery) {
        getPhotos(selectedGallery)
      } else if (!galleryID) {
        getPhotos()
      }
    }
  }, [loading])


  const getPhotos = async (gallery) => {
    gallery ? setSelectedGallery(gallery) : null
    let photos = []
    if (gallery && gallery.name != 'All Photos') {
      const snapshot = await firebase.db
        .collection("photos")
        .where("galleryID", "==", gallery.galleryID)
        .get()
        .catch((error) => {
          console.log("Error getting documents: ", error)
        })
      snapshot.docs.map(doc => {
        photos = photos.concat({
          id: doc.id,
          data: {
            ...doc.data(),
          },
        })
      })
    } else {
      // Create a reference to collection
      const snapshot = await firebase.db
        .collection("photos")
        .where("accountID", "==", authUser.uid)
        .get()
        .catch((error) => {
          console.log("Error getting documents: ", error)
        })

      snapshot.docs.map(doc => {
        photos = photos.concat({
          id: doc.id,
          data: {
            ...doc.data(),
          },
        })
      })
    }
    setPhotos(photos)
    if (!galleryID) {
      doFilter(photos, selectedDate)
    } else {
      sortPhotos(selectedFilter, photos)
    }
  }

  const doFilter = (allPhotos, dateRange) => {
    if (!allPhotos) {
      allPhotos = photos
    }
    setSelectedDate(dateRange)
    const filteredPhotos = allPhotos.filter(p => {
      const startDate = moment(dateRange[0]).startOf("day").toDate()
      const check = p.data.createDate.toDate()
      const endDate = moment(dateRange[1]).endOf("day").toDate()
      return check > startDate && check < endDate
    })
    sortPhotos(selectedFilter, filteredPhotos)
  }


  const fetchMoreData = () => {
    setTimeout(() => {
      let finalCounter = pagination.counter
      if (finalCounter < filteredPhotos.length) {
        if ((finalCounter + 10) < filteredPhotos.length) {
          finalCounter = finalCounter + 10
        } else {
          const count = filteredPhotos.length - finalCounter
          finalCounter = finalCounter + count
        }
        const photosList = filteredPhotos.slice(0, finalCounter)
        const hasMore = filteredPhotos.length > finalCounter
        setPagination({ photos: photosList, counter: finalCounter, hasMore: hasMore })
      } else {
        setPagination({ ...pagination, hasMore: false })
      }
    }, 1000)
  }

  const sortPhotos = async (selected, unsortedPhotos) => {
    setSelectedFilter(selected)
    if (selected === "Date") {
      unsortedPhotos.sort((a, b) => {
        if (b.data.createDate && a.data.createDate) {
          return b.data.createDate.toDate().getTime() - a.data.createDate.toDate().getTime()
        } else {
          return -1
        }
      })
      setFilteredPhotos(unsortedPhotos)
    } else {
      const promiseList = []
      unsortedPhotos.forEach(data => {
        promiseList.push(firebase.db.collection(`photos/${data.id}/views`).get())
      })
      const viewsCount = await Promise.all(promiseList)
      viewsCount.forEach((views, index) => {
        unsortedPhotos[index].data.views = views.size
      })
      unsortedPhotos.sort((a, b) => {
        return b.data.views - a.data.views
      })
      setFilteredPhotos(unsortedPhotos)
    }
    const size = unsortedPhotos.length > 10 ? 10 : unsortedPhotos.length
    setPagination({ photos: unsortedPhotos.slice(0, size), counter: size, hasMore: unsortedPhotos.length > 10 })
  }

  const openDialog = (index) => {
    setShowDialog({ show: true, index: index })
  }

  const deleteItem = (id) => {
    firebase.db.collection("photos").doc(id).delete().then(function () {
      const index = photos.findIndex(data => data.id === id)
      if (index > -1) {
        setShowDialog({ show: false, index: null })
        let photosList = photos
        photosList.splice(index, 1)
        setPhotos(photosList)
        doFilter(photos, selectedDate)
      }
    }).catch(function (error) {
      console.error("Error removing document: ", error)
    })
  }

  const updateIndex = (newIndex) => {
    let index = newIndex
    if (newIndex < 0) {
      index = filteredPhotos.length - 1
    } else if (newIndex === filteredPhotos.length - 1) {
      index = 0
    }
    setShowDialog({ show: true, index: index })
  }


  return (
    <>
      {
        accountInfo || galleryID ?
          <Wrapper>
            {galleryID && <GalleryHeader title={selectedGallery?.name} photoCount={filteredPhotos.length} />}
            {galleryID &&
              <GallerySubHeader selectedDate={selectedDate}
                datePickerSettings={datePickerSettings}
                selectedFilter={selectedFilter}
                sortPhotos={sortPhotos}
                doFilter={doFilter}
                filteredPhotos={filteredPhotos}
              />}
            {accountInfo && <ProfileHeader accountName={`${accountInfo.firstName}`}
              userImage={accountInfo.profileImageUrl ? accountInfo.profileImageUrl : avatar} />}
            {accountInfo && <Container marginTop={16} marginBottom={16} backgroundColor="white" color="#362165">
              <SubHeaderPane>
                <GalleryHeaderTitle>Gallery</GalleryHeaderTitle>
                <FilterOptions>
                  {
                    galleries ?
                      <Pane display="flex" alignItems="center" marginLeft={15}>
                        <FilterTitle>Galleries: </FilterTitle>
                        <Combobox
                          openOnFocus
                          items={galleries.map((g) => g.data)}
                          itemToString={item => item ? item.name : ""}
                          onChange={selected => {
                            getPhotos(selected)
                          }
                          }
                          initialSelectedItem={{ name: "All Photos" }}
                          placeholder="select a gallery"
                        />
                      </Pane> : null
                  }

                  <Pane display="flex" alignItems="center" marginLeft={15}>
                    <FilterTitle>FILTER:</FilterTitle>
                    <Combobox
                      openOnFocus
                      items={["Date", "Popular"]}
                      itemToString={item => item}
                      onChange={selected => sortPhotos(selected, filteredPhotos)}
                      initialSelectedItem={selectedFilter}
                      placeholder=""
                    />
                  </Pane>

                  <Pane display="flex" alignItems="center" marginLeft={15}>
                    <FilterTitle>Date: </FilterTitle>
                    {/*<DateRangePicker format={"ddd,MMM DD,YYYY"}*/}
                    {/*                 placement="bottom"*/}
                    {/*                 preventOverflow*/}
                    {/*                 ranges={ranges}*/}
                    {/*                 value={selectedDate}*/}
                    {/*                 onChange={value => {*/}
                    {/*                   doFilter(photos, value)*/}
                    {/*                 }}*/}
                    {/*/>*/}
                    <DateRangePicker initialSettings={datePickerSettings} onApply={(event, picker) => {
                      doFilter(photos, [picker.startDate.toDate(), picker.endDate.toDate()])
                    }}>
                      <DateDiv>
                        <DateInputSpan>{moment(selectedDate[0]).format("ddd,MMM DD,YYYY")}~{moment(selectedDate[1]).format("ddd,MMM DD,YYYY")}</DateInputSpan>
                        &nbsp;
                        <GoCalendar style={{ color: 'grey' }} />
                      </DateDiv>
                    </DateRangePicker>
                  </Pane>
                </FilterOptions>
              </SubHeaderPane>
            </Container>}
            {accountInfo && <Container marginTop={16} backgroundColor="#F7F7F9" color="#362165">
              {
                selectedGallery
                  ?
                  <>
                    <Pane marginRight={15}>
                      <GalleryTitle>{`${selectedGallery.name}`}</GalleryTitle>
                      <GalleryTitle
                        style={{ color: "#F06878" }}>{selectedGallery.hashTag ? `${selectedGallery.hashTag}` : "#PhrameBooth"}</GalleryTitle>
                    </Pane>
                    <Pane display="flex" alignItems="flex-end" style={{ marginLeft: "10%" }}>
                      <Pane marginRight={15}><img src={ico_photos} alt="" width="60px" height="50px" /></Pane>
                      <PhotosCount>{filteredPhotos.length} <span style={{ fontSize: "20px" }}>photos</span>
                      </PhotosCount>
                    </Pane>
                  </> : null
              }
            </Container>}
            <hr />
            <Container backgroundColor="#F7F7F9" color="#362165" minHeight="100vh">
              <GallerySection>
                <div className="container-fluid">
                  {filteredPhotos.length > 0 ? <InfiniteScroll
                    dataLength={pagination.photos.length}
                    next={() => fetchMoreData()}
                    hasMore={pagination.hasMore}
                    loader={<h4>Loading...</h4>}
                  >
                    <div className="row">
                      {
                        pagination.photos.map((photo, index) => {
                          return <GalleryPhoto key={photo.data.photoID} photo={photo.data}
                            onClick={() => openDialog(index)} />
                        })
                      }
                    </div>
                  </InfiniteScroll> : <NothingToShow> No photos to display</NothingToShow>}
                </div>
              </GallerySection>
            </Container>
            <ViewPhotoDialog
              showDialog={showDialog.show}
              onClose={() => setShowDialog({ show: false, index: showDialog.index })}
              index={showDialog.index}
              updateIndex={updateIndex}
              filteredPhotos={pagination.photos} authUser={authUser} firebase={firebase} deleteItem={deleteItem} />
            <Footer />
          </Wrapper> : null
      }
    </>
  )
}

Galleries.propTypes = {
  authUser: PropTypes.any,
  firebase: PropTypes.any,
}

export default withFirebase(Galleries)
