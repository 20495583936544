import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Pane, Dialog, Alert } from "evergreen-ui"
import { FaFacebookF, FaTwitter, FaArrowLeft, FaArrowRight } from "react-icons/fa"
import { AiOutlineLink, AiOutlineDownload, AiFillDelete } from "react-icons/ai"
import { ActionButton } from "./ActionButton"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import { ShareButton } from "../primitives/ShareButton"
import copy from "copy-to-clipboard"
import dateFormat from "dateformat"

const viewsIcon = require("../../assets/images/analytics/ico_view.svg")

const CopyAlert = styled.div`
	opacity: ${props => props.show ? "1" : "0"};
	transition: all 250ms;
`

const ShareButtonWrapper = styled(ShareButton)`
width:auto;
margin:0.5rem 0;
cursor:pointer;
`

const DialogPane = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	/* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
		flex-direction: row;
	}
`
const CountWrapper = styled.div`
  margin-left: 10px;
`

const Count = styled.h5`
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 123.3%;
color: #362165;
`
const ImageWrapper = styled.div`
  height: 26px;
  padding-top:2px;
`

const ViewCounts = styled.div`
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: 86px;
  height: 26px;
`
const PicDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: red;
  background: rgba(54, 33, 101, 0.6);
  border-radius: 20px;
  width: 100%;
  height: 26px;
  font-size: 14px;
  font-weight: bold;
`

const ShareTitle = styled.h2`
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 30px;
text-align: center;
color: #402C6B;
`

const ViewPhotoDialog = ({ showDialog, onClose, filteredPhotos, index, firebase, authUser, deleteItem, updateIndex }) => {


  const [copyAlert, toggleCopyAlert] = useState(false)
  const [counts, setCounts] = useState(0)
  const [lookupId, setLookUpId] = useState(null)

  useEffect(() => {
    if (index && showDialog) {
      const dockref = firebase.db.doc(`photos/${filteredPhotos[index].id}`)
      firebase.db.collection(`photoLookup`).where("photoReference", "==", dockref).get().then(querySnapshot => {
        if (querySnapshot.size > 0) {
          let id
          querySnapshot.forEach(doc => {
            id = doc.id
          })
          setLookUpId(id)
        }
      })
    }

  }, [index, showDialog])

  useEffect(() => {
    if (index)
      if (filteredPhotos.length > 0 && index >= 0) {
        firebase.db.collection(`photos/${filteredPhotos[index].id}/views`).get().then(data => {
          setCounts(data.size)
        })
      }
  }, [index])


  const handleClose = () => {
    onClose()
  }

  const copyURL = () => {
    copy(`https://www.phramebooth.com/photo/${lookupId}`)
    toggleCopyAlert(true)

    setTimeout(() => {
      toggleCopyAlert(false)
    }, 1500)
  }

  return (
    <Dialog
      isShown={showDialog}
      title="Change photo"
      onCloseComplete={handleClose}
      hasHeader={false}
      hasFooter={false}
      style={{ width: "60%" }}
    >
      {({ close }) => (
        <>{index === null ? <></> :
          <Pane display="flex" flexDirection="row" alignItems="center">
            <Pane>
              <FaArrowLeft style={{ fontSize: "1.5rem", marginRight: "1.5rem", color: "#FF496C" }}
                           onClick={() => updateIndex((index) - 1)}/>
            </Pane>
            <DialogPane>
              <Pane display="flex" flexDirection="column" marginRight={25}>
                <img src={filteredPhotos[index].data.photoURL} alt=""/>
                <Pane display="flex" flexDirection="row" justifyContent={"space-between"}>
                  <ViewCounts>
                    <ImageWrapper>
                      <img src={viewsIcon} width={20} height={20}/>
                    </ImageWrapper>
                    <CountWrapper>
                      <Count>{counts}</Count>
                    </CountWrapper>
                  </ViewCounts>
                  {
                    filteredPhotos[index].data?.createDate !== null ?
                      <PicDate>{dateFormat(filteredPhotos[index].data.createDate.toDate(), "mm.dd.yyyy h:MM TT")}</PicDate> : <></>
                  }
                </Pane>
              </Pane>
              <Pane display="flex" flexDirection="column" justifyContent="center">
                <ShareTitle>Share It:</ShareTitle>
                <FacebookShareButton style={{ width: "auto" }} url={`https://www.phramebooth.com/photo/${lookupId}`}
                                     quote="Shared from PhrameBooth">
                  <ShareButtonWrapper color='#0084FE' height={36}><FaFacebookF/> Facebook</ShareButtonWrapper>
                </FacebookShareButton>
                <TwitterShareButton style={{ width: "auto" }}
                                    url={`https://www.phramebooth.com/photo/${lookupId}`}>
                  <ShareButtonWrapper color='#55ACEE' height={36}><FaTwitter/> Twitter</ShareButtonWrapper>
                </TwitterShareButton>
                <ShareButtonWrapper color='#402C6B' onClick={copyURL} height={36}>
                  <AiOutlineLink/> CopyLink
                </ShareButtonWrapper>
                <a href={filteredPhotos[index].data.photoURL} download="gif" target="_blank"
                   rel="noopener noreferrer"><ShareButtonWrapper color='#402C6B'
                                                                 height={36}><AiOutlineDownload/> Download</ShareButtonWrapper></a>
                {authUser.uid === filteredPhotos[index].data.accountID ?
                  <ShareButtonWrapper color='#FE496C' onClick={() => deleteItem(filteredPhotos[index].id)}
                                      height={36}>
                    <AiFillDelete/> Delete
                  </ShareButtonWrapper> : null}

                <ActionButton backgroundColor="#F06878" color="#F06878" onClick={close} border fontSize={14}
                              marginTop={16} marginBottom={16} marginRight={8} height={46} paddingLeft={16}
                              paddingRight={16}>
                  Cancel
                </ActionButton>

              </Pane>
            </DialogPane>
            <Pane>
              <FaArrowRight style={{ fontSize: "1.5rem", marginLeft: "1.5rem", color: "#FF496C" }}
                            onClick={() => updateIndex((index) + 1)}/>
            </Pane>
          </Pane>}
          <CopyAlert show={copyAlert} style={{ display: `${copyAlert ? "block" : "none"}` }}>
            <Alert
              appearance="card"
              intent="none"
              title="URL copied to clipboard!"
              marginBottom={32}
            />
          </CopyAlert>
        </>

      )}
    </Dialog>
  )
}

ViewPhotoDialog.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.any,
  filteredPhotos: PropTypes.any,
  index: PropTypes.number,
  location: PropTypes.any,
}

export default ViewPhotoDialog
