import React from 'react'
import { compose } from 'recompose'
import SEO from '../components/seo'
import Galleries from '../components/Galleries'
import { AuthUserContext, withAuthorization } from '../components/Session'

const GalleriesPage = () => {
	
	return (
		<>
			<SEO title="Galleries" />
			<AuthUserContext.Consumer>
				{authUser => (
					<div>
						<Galleries authUser={authUser} />
					</div>
				)}
			</AuthUserContext.Consumer>
		</>
	)
}

const condition = authUser => !!authUser

export default compose(
	withAuthorization(condition),
)(GalleriesPage)
