import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Pane } from "evergreen-ui"
import { FaFacebookF, FaTwitter } from "react-icons/fa"
import { Logo } from "./Logo"
import { Link } from "gatsby"
import { FacebookShareButton, TwitterShareButton } from "react-share"

const ico_photos = require("../../assets/images/svgs/ico_photos2.svg")

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 105px;
  background: #362165;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(27.1828px);
  /* Note: backdrop-filter has minimal browser support */

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    flex-direction: row;
  }
`

const BoxLeft = styled.div`
  display: flex;
  flex-basis: 33%;
  justify-content: center;
  padding: 1rem 0;
  
  a {
    width: 180px;
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    align-items: start;
    justify-content: start;
    padding: 0.4rem 5rem;
  }
`

const BoxCenter = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 66%;
  color: #fff;  
 align-items:center;

`

const BoxRight = styled.div`
  flex-basis: 33%;
  display: none;
  align-self: center;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) { 
    display: flex;
  }
`

const Title = styled.h1`
font-family: Poppins;
font-style: normal;
font-weight: 500;
color : #FFFFFF
`
const PhotosCount = styled.h2`
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 45px;
line-height: 55px;
color : #FFFFFF
`
const ShareTitle = styled.h2`
font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #6564DB;
`
const ShareIcons = styled.div`
color:#fff;
font-size:16px;
`

const GalleryHeader = ({ title, photoCount, hashTag = "#pharamebooth", galleryID }) => {
  return (
    <Container>
      <BoxLeft>
        <Link to="/"><Logo width={149} height={90}/></Link>
      </BoxLeft>
      <BoxCenter>
        <Pane>
          <Title style={{ textAlign: "left", fontSize: "45px" }}>{`${title?title:""}`}</Title>
          <Title
            style={{ color: "#F06878", fontSize: "18px", lineHeight: "27px" }}>{hashTag}</Title>
        </Pane>
        <Pane display="flex" style={{ marginLeft: "10%" }}>
          <Pane marginRight={15}><img src={ico_photos} alt="" width="54px" height="54px"/></Pane>
          <PhotosCount>{photoCount} <span style={{ fontSize: "24px" }}>photos</span>
          </PhotosCount>
        </Pane>
      </BoxCenter>
      <BoxRight>
        <ShareTitle>
          Share all gallery:
        </ShareTitle>
        <ShareIcons>
          <FacebookShareButton style={{ marginLeft: "5px", cursor: "pointer" }}
                               url={`https://www.phramebooth.com/gallery/${galleryID}`}
                               quote="Shared from PhrameBooth">
            <FaFacebookF/>
          </FacebookShareButton>
          <TwitterShareButton style={{ marginLeft: "5px", cursor: "pointer" }}
                              url={`https://www.phramebooth.com/gallery/${galleryID}`}>
            <FaTwitter
            /></TwitterShareButton>
        </ShareIcons>
      </BoxRight>
    </Container>
  )
}

GalleryHeader.propTypes = {
  title: PropTypes.string,
  photoCount: PropTypes.any,
}

export default GalleryHeader
